import React from 'react';
import { Outlet } from 'react-router-dom';
import './App.scss';
import {MobileCap} from "./components/mobile-cap/MobileCap";

const App = () => {
  let isMobileDevice: boolean = false;

  const deviceCheck = () => {
    isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
    );
  }

  deviceCheck();

  return (
      <div className='app'>
        {isMobileDevice ? (
            <div className='app__mobile-cap'>
              <MobileCap />
            </div>
        ) : (
            <div className='app__content'>
              <Outlet />
            </div>
        )}
      </div>
  );
}

export default App;
