import './ButtonPrimary.scss';
import spinner from '../../assets/img/button_spinner.svg';
import {SyntheticEvent} from "react";

export const ButtonPrimary = (props: {
  type?: 'button' | 'submit';
  mode?: 'default' | 'light';
  isLoading?: boolean;
  isDisabled?: boolean;
  text: string;
  onClick?: Function;
}) => {
  const { type, mode, isLoading, isDisabled, text, onClick } = props;

  const handleClick = (e: SyntheticEvent) => {
    if (onClick) onClick(e);
  };

  return (
    <>
      <button
        className={`button-primary ${isLoading && 'button-primary--loading'} button-primary--${
          mode || 'default'
        }`}
        type={type || 'button'}
        disabled={isDisabled}
        onClick={handleClick}
      >
        {text}

        <div className='button-primary__loading'>
          <img src={spinner} className='button-primary__loading-icon' alt='Загрузка...' />
          <p className='button-primary__loading-text'>Стоп</p>
        </div>
      </button>
    </>
  );
};
