import './MobileCap.scss';

export const MobileCap = () => {
  return (
    <>
      <div className='mobile-cap'>
        <h1 className='mobile-cap__title'>
          Пожалуйста, откройте этот сайт <br />
          на настольном компьютере <br />
          или воспользуйтесь <br />
          нашим ботом в Telegram
        </h1>

        {/*<div className='mobile-cap__button'>*/}
        {/*  <ButtonPrimary text='Telegram бот' />*/}
        {/*</div>*/}
      </div>
    </>
  );
};
