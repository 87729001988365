import './InputPrimary.scss';
import { SyntheticEvent } from 'react';

export const InputPrimary = (props: {
  name?: string;
  type?: 'text' | 'password';
  placeholder?: string;
  isError?: boolean;
  value?: string;
  onChange?: Function;
}) => {
  const { name, type, placeholder, isError, onChange } = props;

  const onInputChange = (e: SyntheticEvent) => {
    if (onChange) onChange((e.target as HTMLInputElement).value);
  };

  return (
    <>
      <input
        className={`input-primary ${isError && 'input-primary--error'}`}
        name={name || 'input-primary'}
        type={type || 'text'}
        placeholder={placeholder || 'Введите текст'}
        onChange={onInputChange}
        autoCapitalize='off'
        autoComplete='off'
        autoCorrect='off'
        aria-autocomplete='none'
      />
    </>
  );
};
