import {
    OpenAPI, ApiService, SigninReq, SuccessAnsw,
} from '../openapi';

const { postV0Signin } = ApiService;

OpenAPI.BASE = process.env.NODE_ENV === 'development' ? 'https://auth1.atechlab.ru' : window.location.origin;

export const auth = async (data: SigninReq): Promise<SuccessAnsw> => {
    try {
        return await postV0Signin(data);
    } catch (error: any) {
        throw new Error(error);
    }
}
