import './AuthPage.scss';
import { ButtonPrimary } from '../../components/button-primary/ButtonPrimary';
import { InputPrimary } from '../../components/input-primary/InputPrimary';
import {SyntheticEvent, useState} from 'react';
import { useLocation } from 'react-router-dom';
import { auth } from "../../services/api/auth";

export const AuthPage = () => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  let location = useLocation();
  let params = new URLSearchParams(location.search);
  let redirect_url = params.get('redirect_url') || '/';

  const handleAuth = (e: SyntheticEvent) => {
    e.preventDefault();

    setIsLoggingIn(true);
    auth({login, password})
        .then(() => {
          setIsLoggingIn(false);
          window.location.replace(redirect_url);
        })
        .catch(() => {
          setIsLoggingIn(false);
          setError('Ошибка авторизации,\nневерный логин или пароль');
        });
  }

  return (
    <>
      <div className='sign-in'>
        <div className='sign-in__inner'>
          <h1 className='sign-in__title'>Авторизация</h1>
          <form className="sign-in__form">
            <div className='sign-in__form-el'>
              <InputPrimary
                  name='login'
                  placeholder='Логин'
                  type='text'
                  isError={!!error}
                  onChange={setLogin}
              />
            </div>

            <div className='sign-in__form-el'>
              <InputPrimary
                  name='password'
                  placeholder='Пароль'
                  type='password'
                  isError={!!error}
                  onChange={setPassword}
              />
            </div>

            <div className='sign-in__form-expand-wrapper'>
              {!!error && (
                  <div className='sign-in__form-el'>
                    <div className='sign-in__error-info'>{error}</div>
                  </div>
              )}

              <div className='sign-in__form-el'>
                <ButtonPrimary
                    text='Войти'
                    type='submit'
                    isLoading={isLoggingIn}
                    isDisabled={!login?.length || !password?.length || isLoggingIn}
                    onClick={handleAuth}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
