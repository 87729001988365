import './ErrorPage.scss';
import { useRouteError, isRouteErrorResponse } from 'react-router-dom';

export const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);
  let errorMessage: string;

  if (isRouteErrorResponse(error)) {
    errorMessage = error.statusText;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === 'string') {
    errorMessage = error;
  } else {
    console.error(error);
    errorMessage = 'Unknown error';
  }

  return (
    <>
      <div className='error-page'>
        <h1 className='error-page__title'>Oops!</h1>
        <p className='error-page__desc'>Sorry, an unexpected error has occurred.</p>
        <p className='error-page__status'>
          <i>{errorMessage}</i>
        </p>
      </div>
    </>
  );
};
